// Colors
@use "sass:math";

$primary-color: #0062ad;
$secondary-color: #ebebeb;
$body-color: #000;

// Animations
$transition-time: .15s;

// Grid / layout
$grid-gutter: 60px;
$section-padding: calculateRem(80px);
$vr: calculateRem(30px);

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1740px,
);

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1680px,
);

// Typings
$font-size-mobile: 16px;
$font-size-tablet: 18px;
$font-size-desktop: 20px;
$font-family-body: myriad-pro-condensed, sans-serif;
$font-family-headings: myriad-pro-condensed, sans-serif;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-semibold: 600;
$font-weight-bold: 700;

// Functions
@function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
    $min: map-get($breakpoints, $name);
    @return if($min != 0, $min, null);
}

@function breakpoint-next($name, $breakpoints: $grid-breakpoints, $breakpoint-names: map-keys($breakpoints)) {
    $n: index($breakpoint-names, $name);
    @return if($n != null and $n < length($breakpoint-names), nth($breakpoint-names, $n + 1), null);
}

@function breakpoint-max($name, $breakpoints: $grid-breakpoints) {
    $next: breakpoint-next($name, $breakpoints);
    @return if($next, breakpoint-min($next, $breakpoints) - .02, null);
}

@function breakpoint-infix($name, $breakpoints: $grid-breakpoints) {
    @return if(breakpoint-min($name, $breakpoints) == null, "", "-#{$name}");
}

// Mixins
@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
    $min: breakpoint-min($name, $breakpoints);

    @if $min {
        @media (min-width: $min) {
            @content;
        }
    } @else {
        @content;
    }
}

@mixin media-breakpoint-down($name, $breakpoints: $grid-breakpoints) {
    $max: breakpoint-max($name, $breakpoints);
    @if $max {
        @media (max-width: $max) {
            @content;
        }
    } @else {
        @content;
    }
}

// Functions
@function calculateRem($size) {
    @return math.div($size, $font-size-desktop) * 1rem;
}

// Helpers
%link-btn {
    display: inline-flex;
    align-items: center;
    padding: calculateRem(12px) calculateRem(16px);
    color: $primary-color;
    background: none;
    border: 1px solid $primary-color;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: $font-weight-bold;
    transition: all $transition-time;
    cursor: pointer;

    &:active,
    &:hover {
        background-color: $primary-color;
        color: #fff;
        text-decoration: none;
    }
}

%link-underline {
    display: inline-block;
    text-decoration: none;

    &::after {
        content: '';
        display: block;
        width: 0;
        height: 1px;
        background-color: currentColor;
        transition: width 0.3s;
    }

    &:hover,
    &.active {
        color: currentColor;
        text-decoration: none;

        &::after {
            width: 100%;
        }
    }
}
