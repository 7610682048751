.process {
    $this: &;
    overflow: hidden;

    &--single {
        #{$this}__icon svg .process-arrow--1 {
            opacity: 1;
        }

        #{$this}__content {
            @include media-breakpoint-up(md) {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        }

        #{$this}__images {
            margin: 0 auto 1rem;
            padding: 0 ($grid-gutter * 0.5);

            @include media-breakpoint-up(sm) {
                max-width: 540px;
            }

            @include media-breakpoint-up(md) {
                position: static;
                max-width: 100%;
                min-width: 50%;
                margin: 0;
                padding: 0;
            }

            img {
                width: 100%;
                height: auto;
                object-fit: fill;
                object-position: center;
            }
        }

        #{$this}__items {
            margin: 0 auto;
            padding: 0 ($grid-gutter * 0.5);

            @include media-breakpoint-up(sm) {
                max-width: 540px;
            }

            @include media-breakpoint-up(md) {
                max-width: 100%;
                min-width: 50%;
                margin: 0;
                padding-right: calc(50vw - 360px + #{$grid-gutter * 0.5}); // half of .container width + right gutter
                padding-top: calculateRem(40px);
                padding-bottom: calculateRem(40px);
            }

            @include media-breakpoint-up(lg) {
                padding-right: calc(50vw - 480px + #{$grid-gutter * 0.5}); // half of .container width + right gutter
                padding-top: calculateRem(80px);
                padding-bottom: calculateRem(80px);
            }

            @include media-breakpoint-up(xl) {
                padding-right: calc(50vw - 570px + #{$grid-gutter * 0.5}); // half of .container width + right gutter
                padding-top: calculateRem(140px);
                padding-bottom: calculateRem(120px);
                padding-left: 4rem;
            }

            @include media-breakpoint-up(xxl) {
                padding-right: calc(50vw - 840px + 115px); // half of .container width + right gutter
                padding-left: calculateRem(110px);
            }
        }

        #{$this}__item {
            display: block;
        }

        #{$this}__text {
            padding-right: 0;
        }
    }

    > .container {
        position: relative;
        padding-top: calculateRem(40px);
        padding-bottom: calculateRem(40px);

        @include media-breakpoint-up(md) {
            padding-bottom: 0;
        }

        &::before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: -1000rem;
            background-color: #fff;
            z-index: 0;
        }

        > h2 {
            margin-bottom: calculateRem(40px);
        }

        > * {
            position: relative;
            z-index: 1;
        }
    }

    &__content {
        position: relative;
    }

    &__images {
        @include media-breakpoint-up(md) {
            position: absolute;
            width: 50vw;
            height: 100%;
            right: 50%;
            top: 0;
        }
    }

    &__image {
        display: none;
        margin: 0;
        height: 100%;

        &--active {
            display: block;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: right center;
        }
    }

    &__icon {
        position: absolute;
        bottom: 0;
        right: 0;
        width: calculateRem(100px);
        height: calculateRem(100px);

        @include media-breakpoint-up(md) {
            top: calc(50% - #{calculateRem(100px)});
            left: calc(50% - #{calculateRem(100px)});
            width: calculateRem(200px);
            height: calculateRem(200px);
        }

        @include media-breakpoint-up(xl) {
            top: calc(50% - #{calculateRem(200px)});
            left: calc(50% - #{calculateRem(200px)});
            width: calculateRem(400px);
            height: calculateRem(400px);
        }

        svg {
            .process-arrow {
                opacity: .5;
                fill: $primary-color;
                transition: all $transition-time;

                &--active {
                    opacity: 1;
                }
            }
        }
    }

    &__items {
        padding-top: calculateRem(20px);

        @include media-breakpoint-up(md) {
            margin-left: 50%;
            width: 50%;
        }
    }

    &__item {
        display: none;

        &--active {
            display: flex;
            flex-direction: column;

            @include media-breakpoint-up(md) {
                min-height: calculateRem(400px);
                padding: calculateRem(40px) 0 calculateRem(40px) calculateRem(150px);
            }

            @include media-breakpoint-up(xl) {
                min-height: calculateRem(500px);
                padding: calculateRem(80px) 0 calculateRem(80px) calculateRem(265px);
            }
        }
    }

    &__headline {
        margin-bottom: calculateRem(20px);
    }

    &__text {
        padding-right: calculateRem(100px);

        @include media-breakpoint-up(md) {
            margin-bottom: calculateRem(50px);
            padding-right: 0;
        }
    }

    &__link-wrapper {
        margin-top: calculateRem(20px);

        @include media-breakpoint-up(md) {
            margin-top: auto;
        }
    }

    &__link {
        @extend %link-btn;
        font-size: inherit;
        font-family: inherit;
    }
}
