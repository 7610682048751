.section-main {
    body.page-background & {
        background: url('../../images/page-bg.jpg') 0 0 repeat;
    }

    body.page-background--oxara & {
        background: url("../../images/oxara-background.jpg") 0 0 repeat;
    }

    .bg-grey {
        background: #eee;
    }

    .mod_article.social-media {
        padding-top: 2em;
        padding-bottom: 4em;
        color: #fff;
        background: $primary-color;

        h2 {
            color: #fff;
        }

        .video_container {
            margin-bottom: 0;
        }
    }

    .content-slider {
        padding-bottom: calculateRem(40px);
    }

    .swiper {
        &-button-prev,
        &-button-next {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: calc(50% - #{calculateRem(25px)});
            width: calculateRem(50px);
            height: calculateRem(50px);
            background-color: #eee;
            opacity: .7;
            z-index: 1;
            cursor: pointer;
            transition: all $transition-time;

            &:not(.swiper-button-disabled):hover {
                opacity: 1;
            }

            &::before {
                content: '';
                border-right: 3px solid #000;
                border-top: 3px solid #000;
                display: block;
                width: calculateRem(15px);
                height: calculateRem(15px);
            }
        }

        &-button-prev {
            left: calculateRem(25px);

            &::before {
                margin-left: 5px;
                transform: rotate(-135deg);
            }
        }

        &-button-next {
            right: calculateRem(25px);

            &::before {
                margin-right: 5px;
                transform: rotate(45deg);
            }
        }

        &-button-disabled {
            opacity: .5;
            cursor: not-allowed;
        }

        &-button-lock {
            display: none !important;
        }

        &-pagination {
            display: flex;
            justify-content: center;
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            z-index: 1;

            &-lock {
                display: none !important;
            }

            &-bullet {
                margin: 0 5px;
                display: block;
                width: calculateRem(16px);
                height: calculateRem(16px);
                border: 2px solid $primary-color;
                border-radius: 50%;
                cursor: pointer;
                transition: all $transition-time;

                &-active {
                    background-color: $primary-color
                }
            }
        }
    }
}
