.splash {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calculateRem(200px);
    padding: 5em 0;
    z-index: 1;

    &__image {
        opacity: 0;
        margin: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: opacity .8s ease-in-out;

        &--current {
            opacity: 1;
        }

        img,
        video {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__text-wrapper {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: calculateRem(200px);
        padding: 5em 0;
        z-index: 1;
    }

    &__text {
        @extend .container;
        @extend .container-fluid;
        max-width: 920px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        text-align: center;
        font-size: 1.2rem;
        color: #fff;

        &--white {
            > * {
                background: rgba(0, 0, 0, 0.45);
                color: #fff;
                padding: 10px;
            }

            p.arrow-link {
                padding: 0;
                animation: arrow-link 1s ease-in-out infinite alternate;

                a {
                    position: relative;
                    display: inline-block;
                    height: 42px;
                    width: 62px;
                    color: #fff;
                    font-weight: 600;
                    text-indent: -999em;
                    border: 2px solid #fff;
                    transition: background-color .2s;

                    &:before {
                        border-bottom: 3px solid #fff;
                        border-right: 3px solid #fff;
                        content: "";
                        display: block;
                        height: 20px;
                        left: 19px;
                        position: absolute;
                        top: 3px;
                        transform: rotate(45deg);
                        width: 20px;
                    }
                }

                @keyframes arrow-link {
                    0% {
                        transform: translateX(0);
                    }

                    0% {
                        transform: translateY(20px);
                    }
                }
            }
        }

        &--primary {
            color: $primary-color;
        }

        blockquote + p {
            margin-top: calculateRem(20px);
            font-size: calculateRem(28px);
        }

        p:first-child {
            margin-bottom: 8px;
            font-size: 1.7rem;
            font-weight: 700;
        }

        p:last-child {
            margin-bottom: 0;
        }

        @include media-breakpoint-up(lg) {
            p:first-child {
                font-size: calculateRem(45px);
            }
        }
    }
}
