.references-grid-wrapper {
    padding-top: calculateRem(50px);
    background-color: #fff;

    @include media-breakpoint-up(md) {
        padding-bottom: calculateRem(50px);
    }

    > .container > h2 {
        margin-bottom: calculateRem(40px);
    }
}

.references-grid {
    margin-bottom: $vr;
    width: 100%;

    @include media-breakpoint-up(md) {
        margin-bottom: 0;
        width: 33.3333%;
    }

    &__image {
        margin-bottom: calculateRem(30px);
    }

    &__more {
        margin-top: calculateRem(30px);
        margin-bottom: 0;

        a {
            @extend %link-btn;
        }
    }
}

.references-list {
    $this: &;
    padding: calculateRem(58px) 0;

    &:nth-child(odd) > .container {
        @include media-breakpoint-up(md) {
            padding-right: 0;
        }

        #{$this}__content {
            @include media-breakpoint-up(md) {
                padding-right: $grid-gutter * 0.5;
            }

            @include media-breakpoint-up(xxl) {
                padding-right: calculateRem(90px);
            }
        }
    }

    &:nth-child(even) {
        background-color: #eee;

        > .container {
            @include media-breakpoint-up(md) {
                padding-left: 0;
            }
        }

        #{$this}__images {
            order: 0;
        }

        #{$this}__content {
            @include media-breakpoint-up(md) {
                padding-left: $grid-gutter * 0.5;
                order: 1;
            }

            @include media-breakpoint-up(xxl) {
                padding-left: calculateRem(90px);
            }
        }
    }

    // Start with the grey background if the references are coming after section-intro
    .section-intro + .mod_article & {
        &:nth-child(even) {
            background-color: transparent;
        }

        &:nth-child(odd) {
            background-color: #eee;
        }
    }

    > .container {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        @include media-breakpoint-up(md) {
            flex-wrap: nowrap;
        }
    }

    &__images {
        position: relative;
        margin-bottom: $vr;
        width: 100%;

        @include media-breakpoint-up(md) {
            margin-bottom: 0;
            width: calculateRem(345px);
            min-width: calculateRem(345px);
        }

        @include media-breakpoint-up(xl) {
            width: calculateRem(576px);
            min-width: calculateRem(576px);
        }

        figure {
            margin: 0;

            @include media-breakpoint-up(md) {
                width: calculateRem(345px);
            }

            @include media-breakpoint-up(xl) {
                width: calculateRem(576px);
            }

            img {
                width: 100%;
                object-fit: cover;
            }
        }

        .video_container {
            a {
                &:before {
                    content: "";
                    position: absolute;
                    left: 14.4rem;
                    top: 14.4rem;
                    width: 200px;
                    height: 200px;
                    margin-left: -100px;
                    margin-top: -100px;
                    border-radius: 50%;
                    border: 10px solid white;
                    pointer-events: none;
                }

                &:after {
                    content: "";
                    position: absolute;
                    left: 14.4rem;
                    top: 14.4rem;
                    width: 0;
                    height: 0;
                    margin-left: -25px;
                    margin-top: -50px;
                    border-top: 50px solid transparent;
                    border-bottom: 50px solid transparent;
                    border-left: 75px solid #fff;
                    pointer-events: none;
                }
            }
        }

        .swiper {
            &-pagination {
                bottom: calculateRem(14px);

                &-bullet {
                    border-color: #fff;

                    &-active {
                        background-color: #fff
                    }
                }
            }
        }
    }

    &__content {
        order: 1;

        @include media-breakpoint-up(md) {
            order: 0;
        }
    }

    &__info {
        margin-bottom: calculateRem(10px);
        color: #666;
    }

    &__more a {
        @extend %link-btn;
    }

    &__details {
        margin-top: $vr;
        display: flex;
        flex-wrap: wrap;

        @include media-breakpoint-up(md) {
            margin-top: calculateRem(60px);
        }
    }

    &__enclosures {
        margin: 0 calculateRem(60px) 0 0;
        padding: 0;
        list-style: none;
    }

    &__enclosure {
        margin: 0;
        padding: 0;
        list-style: none;

        &-link {
            display: block;
            width: 60px;
            height: 75px;
            background: url('../../images/icon-pdf.svg') center/contain no-repeat;
            transition: all $transition-time;

            &:hover {
                transform: translateY(10%);
            }
        }
    }

    &__co2 {
        color: $primary-color;
        font-weight: $font-weight-bold;
        text-align: center;

        &-value {
            font-size: calculateRem(40px);
        }
    }
}
