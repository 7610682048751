.section-gray-large-image {
    padding: calculateRem(40px) 0;
    background: #eee;

    .container {

        @include media-breakpoint-up(lg) {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        @include media-breakpoint-up(xxl) {
            padding-left: $grid-gutter * 0.5;
        }
    }

    .content-image {
        margin: 0 0 calculateRem(40px) 0;
        min-width: 50%;

        @include media-breakpoint-up(lg) {
            margin-bottom: 0;
        }

        figure {
            margin: 0;
        }
    }

    .content-text {
        margin: 0;
        text-align: center;

        @include media-breakpoint-up(lg) {
            padding-left: calculateRem(50px);
            max-width: calculateRem(430px);
            text-align: left;
        }

        p:last-child {
            margin-bottom: 0;
        }
    }
}
