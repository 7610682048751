.section-footer {
    padding: calculateRem(50px) 0;
    background-color: $primary-color;
    color: #fff;
    line-height: 1.5714285714;

    &__inside {
        @extend .container;
        @extend .container-fluid;

        > .row > * {
            width: 100%;

            &:not(:last-child) {
                margin-bottom: calculateRem(25px);
            }

            @include media-breakpoint-up(md) {
                width: 50%;

                &:nth-child(3),
                &:nth-child(4) {
                    margin-bottom: 0;
                }
            }

            @include media-breakpoint-up(lg) {
                margin-bottom: 0;
                width: 25%;
            }
        }
    }

    .content-text > .rte > *:last-child {
        margin-bottom: 0;
    }

    h3 {
        margin-bottom: calculateRem(22px);
        padding: calculateRem(11px) 0;
        font-size: inherit;
        color: #fff;
        border-top: 1px solid #fff;
        border-bottom: 1px solid #fff;
    }

    ul, li {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    a {
        @extend %link-underline;
        color: #fff;
    }
}
