@import url("https://use.typekit.net/ulx1tde.css");

@font-face {
    font-family: 'fontello';
    src: url('../fonts/fontello.eot');
    src: url('../fonts/fontello.eot') format('embedded-opentype'), url('../fonts/fontello.woff') format('woff'), url('../fonts/fontello.ttf') format('truetype');
    font-weight: normal;
    font-style: normal
}

@font-face {
    font-family: 'fontello';
    src: url('../fonts/fontello.eot');
    src: url('../fonts/fontello.eot') format('embedded-opentype'), url('../fonts/fontello.woff') format('woff'), url('../fonts/fontello.ttf') format('truetype');
    font-weight: normal;
    font-style: normal
}
