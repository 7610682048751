.section-header {
    &__inside {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        @include media-breakpoint-up(sm) {
            align-items: stretch;
            justify-content: center;
        }
    }

    &__logo {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: calculateRem(20px) ($grid-gutter * 0.5);

        @include media-breakpoint-up(md) {
            width: 100%;
            padding: calculateRem(50px) 0;
        }

        img {
            width: calculateRem(150px);

            @include media-breakpoint-up(md) {
                width: calculateRem(230px);
            }
        }
    }

    &__navigation-trigger {
        display: flex;
        align-items: center;
        margin-right: $grid-gutter * 0.5;
        width: 40px;
        height: 30px;
        border-top: 5px solid $secondary-color;
        border-bottom: 5px solid $secondary-color;

        body.navigation-visible & {
            border-color: $primary-color;
        }

        &::before {
            content: '';
            display: block;
            width: 100%;
            height: 5px;
            background-color: $secondary-color;

            body.navigation-visible & {
                background-color: $primary-color;
            }
        }

        @include media-breakpoint-up(sm) {
            display: none;
            margin-right: 0;
        }
    }

    &__overlay {
        position: relative;
        display: none;
        width: 100%;
        font-size: calculateRem(18px);
        color: #aaaaaa;
        background: #ebebeb;

        @include media-breakpoint-down(xs) {
            body.navigation-visible & {
                display: block;
            }
        }

        @include media-breakpoint-up(sm) {
            display: block;
        }
    }

    &__navigation {
        ul, li {
            margin: 0;
            padding: 0;
            list-style: none;
        }

        .level_1 {
            @include media-breakpoint-up(sm) {
                display: flex;
                justify-content: center;
            }

            > li {
                position: relative;

                @include media-breakpoint-up(sm) {
                    margin: 0 calculateRem(16px);
                }

                &:hover {
                    > a,
                    strong {
                        color: $primary-color;
                    }

                    > .level_2 {
                        @include media-breakpoint-down(xs) {
                            display: block;
                        }

                        @include media-breakpoint-up(sm) {
                            opacity: 1;
                            pointer-events: auto;
                        }
                    }
                }

                @include media-breakpoint-down(xs) {
                    &.active > .level_2,
                    &.trail > .level_2 {
                        display: block;
                    }
                }

                > a,
                strong {
                    display: flex;
                    justify-content: center;
                    padding: calculateRem(14px);
                    font-size: calculateRem(18px);
                    font-weight: $font-weight-semibold;
                    color: #aaaaaa;
                    text-decoration: none;
                    transition: all $transition-time;

                    @include media-breakpoint-up(sm) {
                        display: block;
                        padding: calculateRem(14px) 0;
                    }
                }

                a:hover,
                a.trail,
                strong {
                    color: $primary-color;
                }
            }
        }

        .level_2 {
            display: none;

            @include media-breakpoint-up(sm) {
                display: block;
                opacity: 0;
                pointer-events: none;
                transition: all $transition-time;
                position: absolute;
                left: calculateRem(-30px); // same as a,strong padding-left
                min-width: calculateRem(230px);
                top: 100%;
                z-index: 5;
            }

            > li {
                > a,
                strong {
                    display: flex;
                    justify-content: center;
                    padding: calculateRem(16px) calculateRem(30px);
                    font-size: calculateRem(18px);
                    font-weight: $font-weight-semibold;
                    background-color: $primary-color;
                    color: #fff;
                    text-decoration: none;
                    transition: all $transition-time;

                    @include media-breakpoint-up(sm) {
                        display: block;
                    }
                }

                a:hover,
                a.trail,
                strong {
                    background-color: #fff;
                    color: $primary-color;
                }
            }
        }
    }

    &__language {
        ul, li {
            margin: 0;
            padding: 0;
            list-style-type: none;
        }

        ul {
            display: flex;
            justify-content: center;
            padding: calculateRem(16px) 0;
            border-top: 1px solid #fff;
        }

        a {
            padding: calculateRem(14px);
            font-size: calculateRem(18px);
            font-weight: $font-weight-semibold;
            color: #aaaaaa;
            text-decoration: none;
            transition: all $transition-time;

            &:hover {
                color: $primary-color;
                text-decoration: none;
            }
        }

        @include media-breakpoint-up(sm) {
            position: absolute;
            top: -1px;
            right: 20px;
            z-index: 10;
        }
    }
}
