@use "sass:math";

@import 'defaults';

html {
    box-sizing: border-box;
    font-size: $font-size-mobile;
    scroll-behavior: smooth;

    @include media-breakpoint-up(md) {
        font-size: $font-size-tablet;
    }

    @include media-breakpoint-up(lg) {
        font-size: $font-size-desktop;
    }

    @media screen and (prefers-reduced-motion: reduce) {
        scroll-behavior: auto;
    }
}

*, *:before, *:after {
    box-sizing: inherit;
}

body, form, figure, blockquote {
    margin: 0;
    padding: 0
}

img, svg, iframe {
    display: block;
    max-width: 100%;
    border: 0;
}

img, svg {
    height: auto;
}

img[sizes] {
    width: auto;
}

fieldset {
    border: none;
    margin: 0;
    padding: 0;
}

hr {
    height: 1px;
    margin: 3.5em 0;
    border: none;
    background-color: $body-color;

    & + .content-text {
        margin-top: -1em;
    }
}

header, footer, main, nav, section, aside, article, figure, figcaption {
    display: block
}

html, body, form, fieldset, p, div, h1, h2, h3, h4, h5, h6 {
    -webkit-text-size-adjust: none;
}

body, input, textarea, select {
    font-family: $font-family-body;
    font-weight: $font-weight-light;
    line-height: 1.2;
    letter-spacing: 0.04em;
    color: $body-color;
}

/* Vertical rhythm */
ul, ol, dd,
p, figure,
pre, table, fieldset {
    margin-top: 0;
    margin-bottom: $vr;
}

select, input, textarea {
    font-size: 99%;
}

strong {
    font-weight: $font-weight-bold;
}

a {
    color: $primary-color;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
    margin: 0;
    color: $primary-color;
    font-family: $font-family-headings;
    font-size: inherit;
    font-weight: $font-weight-bold;
    line-height: 1.1333333333;
    letter-spacing: 0;
}

h1, .h1,
h2, .h2 {
    margin-bottom: calculateRem(20px);
    font-size: calculateRem(34px);
}

h3, .h3 {
    font-size: calculateRem(30px);
}

h4, .h4 {
    margin: 1em 0;
}

figcaption {
    padding: 5px 0;
    font-size: 14px;
    color: $body-color;
    text-align: left;
    letter-spacing:0.02em;
}

sup {
    vertical-align: baseline;
    position: relative;
    top: -0.3em;
    font-size: .75em;
}

sub {
    font-size: .8em;
    vertical-align: baseline;
}

.main {
    overflow: hidden;
}

.overflow {
    overflow: visible;
}

.clear, #clear {
    height: .1px;
    font-size: .1px;
    line-height: .1px;
    clear: both
}

.back a {
    @extend %link-btn;

    &::before {
        content: '<';
        margin-right: 10px;
    }
}

.invisible {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}

.sr-only {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important; // Fix for https://github.com/twbs/bootstrap/issues/25686
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border: 0 !important;
}

.container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: $grid-gutter * 0.5;
    padding-right: $grid-gutter * 0.5;

    @include media-breakpoint-up(xxl) {
        padding-left: 145px;
        padding-right: 145px;
    }
}

.container-semifluid {
    @include media-breakpoint-up(xxl) {
        padding-left: 95px;
        padding-right: 95px;
    }
}

.container-fluid {
    @include media-breakpoint-up(xxl) {
        padding-left: $grid-gutter * 0.5;
        padding-right: $grid-gutter * 0.5;
    }
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-left: math.div($grid-gutter, -4);
    margin-right: math.div($grid-gutter, -4);

    @include media-breakpoint-up(xl) {
        margin-left: math.div($grid-gutter, -2);
        margin-right: math.div($grid-gutter, -2);
    }

    > * {
        flex: 0 0 auto;
        padding-left: $grid-gutter * 0.25;
        padding-right: $grid-gutter * 0.25;

        @include media-breakpoint-up(xl) {
            padding-left: $grid-gutter * 0.5;
            padding-right: $grid-gutter * 0.5;
        }
    }
}

.mod_article {
    margin-bottom: calculateRem(40px);

    @include media-breakpoint-up(md) {
        margin-bottom: calculateRem(60px);
    }

    body.page-background & {
        margin-bottom: calculateRem(40px);

        @include media-breakpoint-up(md) {
            margin-bottom: calculateRem(80px);
        }
    }

    &:first-child,
    &:last-child {
        margin-bottom: 0;

        body.page-background & {
            margin-bottom: 0;
        }
    }
}

.content-list ul,
.content-text ul {
    padding-left: 1rem;
}

.content-youtube .aspect {
    position: relative;
    height: 0;

    &.aspect--16\:9 {
        padding-bottom: 100% / 16 * 9;
    }

    &.aspect--16\:10 {
        padding-bottom: 100% / 16 * 10;
    }

    &.aspect--21\:9 {
        padding-bottom: 100% / 21 * 9;
    }

    &.aspect--4\:3 {
        padding-bottom: 100% / 4 * 3;
    }

    &.aspect--3\:2 {
        padding-bottom: 100% / 3 * 2;
    }

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.mb-0 {
    margin-bottom: 0 !important;
}

@each $breakpoint, $container-max-width in $container-max-widths {
    @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
        %responsive-container-#{$breakpoint} {
            max-width: $container-max-width;
        }

        // Extend each breakpoint which is smaller or equal to the current breakpoint
        $extend-breakpoint: true;

        @each $name, $width in $grid-breakpoints {
            @if ($extend-breakpoint) {
                .container#{breakpoint-infix($name, $grid-breakpoints)} {
                    @extend %responsive-container-#{$breakpoint};
                }

                // Once the current breakpoint is reached, stop extending
                @if ($breakpoint == $name) {
                    $extend-breakpoint: false;
                }
            }
        }
    }
}
