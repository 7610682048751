.contact {
    padding: calculateRem(40px) 0;
    background: $primary-color;
    color: #fff;
    text-align: center;

    @include media-breakpoint-up(md) {
        padding: calculateRem(130px) 0 calculateRem(80px) 0;
    }

    &__headline {
        margin-bottom: calculateRem(40px);
        color: inherit;
        font-size: calculateRem(45px);
    }

    &__text {
        max-width: 700px;
        margin: 0 auto calculateRem(40px) auto;
        font-size: calculateRem(30px);
        font-weight: $font-weight-bold;

        @include media-breakpoint-up(md) {
            margin-bottom: calculateRem(80px);
        }
    }

    &__links {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0;
        padding: 0;
        list-style: none;

        &-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 0;
            padding: 0;
            list-style: none;

            &:not(:last-child) {
                margin-bottom: 2rem;
            }

            &::before {
                content: '';
                display: block;
                margin-bottom: 1rem;
                background-repeat: no-repeat;
                background-size: cover;
            }

            &--email::before {
                width: calculateRem(53px);
                height: calculateRem(38px);
                background-image: url('../../images/icon-email-white.svg');
            }

            &--phone::before {
                width: calculateRem(32px);
                height: calculateRem(59px);
                background-image: url('../../images/icon-phone-white.svg');
            }
        }

        &-link {
            @extend %link-underline;
            color: inherit;
            font-size: calculateRem(25px);
        }
    }
}
