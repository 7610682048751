.statistics {
    padding: calculateRem(40px) 0 0 0;
    background: url('../../images/statistics-bg.jpg') center bottom/cover no-repeat;

    @include media-breakpoint-up(xxl) {
        padding: calculateRem(80px) 0 calculateRem(150px);
    }

    &__items {
        display: flex;
        flex-wrap: wrap;
        padding: calculateRem(70px) 0 0 0;

        @include media-breakpoint-up(lg) {
            padding: calculateRem(70px) calculateRem(95px) 0 calculateRem(95px);
        }
    }

    &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        text-align: center;
        margin-bottom: calculateRem(80px);
        color: $primary-color;
        font-weight: $font-weight-bold;

        @include media-breakpoint-up(md) {
            width: 50%;
            padding: 0 calculateRem(50px);
            align-items: stretch;
            text-align: left;
        }

        @include media-breakpoint-up(lg) {
            width: calculateRem(340px);
        }
    }

    &__image {
        display: flex;
        align-items: center;
        height: calculateRem(200px);
        margin-bottom: calculateRem(30px);

        img {
            width: auto;
            height: calculateRem(175px);
        }
    }

    &__value {
        font-size: calculateRem(60px);
    }

    &__label {
        font-size: calculateRem(26px);
    }
}
