.calculator-form {
    margin-bottom: 0;

    &__inner {
        padding-top: 100px;

        @include media-breakpoint-up(lg) {
            padding-top: 140px;
        }

        @include media-breakpoint-up(xl) {
            padding-top: 0;
        }
    }

    &__image {
        display: block;
        margin: 0;
        width: 100px;
        height: 85px;
        position: absolute;
        top: 0;
        left: calc(50% - 42.5px);
        pointer-events: none;
        z-index: 0;

        @include media-breakpoint-up(lg) {
            width: 137px;
            height: 116px;
            left: calc(50% - 68.5px);
        }

        @include media-breakpoint-up(xl) {
            top: calculateRem(60px); // padding-top
            left: 100%;
        }
    }

    &__form {
        margin-bottom: calculateRem(10px);
    }

    &__widget {
        &:last-child {
            margin-bottom: 0;
        }

        &-inline {
            display: flex;

            input {
                margin-right: 10px;
                padding-left: calculateRem(5px);
                padding-right: calculateRem(5px);
                text-align: center;
            }
        }

        label {
            display: block;
            margin-bottom: 10px;
        }

        input {
            width: 100%;
            padding: calculateRem(14px) calculateRem(15px);
            border: none;
            background: #fff;
            color: $primary-color;
            font-family: $font-family-body;
            font-size: calculateRem(16px);
            font-weight: $font-weight-bold;

            &:focus {
                outline: none;
            }

            &::placeholder {
                color: $primary-color;
                opacity: 1;
            }

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            &[type=number] {
                -moz-appearance: textfield;
            }
        }
    }

    &__button {
        padding: calculateRem(14px) calculateRem(15px);
        border: 2px solid #fff;
        background-color: transparent;
        color: #fff;
        font-family: $font-family-body;
        font-size: calculateRem(16px);
        font-weight: $font-weight-bold;
        cursor: pointer;
        transition: all $transition-time;
        white-space: nowrap;

        &:focus {
            outline: none;
        }

        &:hover {
            background-color: #fff;
            color: $primary-color;
        }
    }
}
