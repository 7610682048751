.section-image-right {
    position: relative;
    overflow: hidden;

    @include media-breakpoint-up(md) {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .content-image {
        margin: 0 auto 1rem;
        padding: 0 ($grid-gutter * 0.5);

        @include media-breakpoint-up(sm) {
            max-width: 540px;
        }

        @include media-breakpoint-up(md) {
            max-width: 100%;
            min-width: 50%;
            margin: 0;
            padding: 0;
            order: 1;
        }

        figure {
            margin: 0;
        }
    }

    .content-text {
        margin: 0 auto 2rem;
        padding: 0 ($grid-gutter * 0.5);

        @include media-breakpoint-up(sm) {
            max-width: 540px;
        }

        @include media-breakpoint-up(md) {
            max-width: 100%;
            min-width: 50%;
            margin: 0;
            padding-left: calc(50vw - 360px + #{$grid-gutter * 0.5}); // half of .container width + left gutter
            padding-top: calculateRem(40px);
            padding-bottom: calculateRem(40px);
        }

        @include media-breakpoint-up(lg) {
            padding-left: calc(50vw - 480px + #{$grid-gutter * 0.5}); // half of .container width + left gutter
            padding-top: calculateRem(80px);
            padding-bottom: calculateRem(80px);
        }

        @include media-breakpoint-up(xl) {
            padding-left: calc(50vw - 570px + #{$grid-gutter * 0.5}); // half of .container width + left gutter
            padding-top: calculateRem(140px);
            padding-bottom: calculateRem(120px);
        }

        @include media-breakpoint-up(xxl) {
            padding-left: calc(50vw - 840px + 145px); // half of .container width + left gutter
            padding-right: calculateRem(110px);
        }

        p:last-child {
            margin-bottom: 0;

            a {
                @extend %link-btn;
            }
        }
    }
}
