.section-intro {
    overflow: hidden;

    @include media-breakpoint-up(md) {
        padding-top: calculateRem(60px);
    }

    .container {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;

        > *:first-child {
            width: 100%;
            padding: $vr 0;

            // No padding at the bottom if it's the only element in section
            &:last-child {
                padding-bottom: 0;
            }

            @include media-breakpoint-up(md) {
                width: 50%;
                padding: calculateRem(40px) ($grid-gutter * 0.5) 0 0;

                // Expand to 100% width if it's the only element in section
                &:last-child {
                    width: 100%;
                }
            }

            @include media-breakpoint-up(lg) {
                width: 70%;
                padding-top: calculateRem(57px);

                &:last-child {
                    padding-top: calculateRem(40px);
                    width: 70%;
                }
            }

            @include media-breakpoint-up(xxl) {
                padding-right: calculateRem(100px);
            }

            .rte:last-child p:last-child {
                @include media-breakpoint-down(sm) {
                    margin-bottom: 0;
                }

                a {
                    @extend %link-btn;
                }
            }

            figure {
                margin-top: calculateRem(60px);
            }
        }

        > *:nth-child(2) {
            position: relative;
            width: 100%;
            padding: $vr 0;
            color: #fff;

            @include media-breakpoint-up(md) {
                width: 50%;
                padding: calculateRem(60px) 0 calculateRem(60px) ($grid-gutter * 0.5);
            }

            @include media-breakpoint-up(lg) {
                width: 30%;
            }

            @include media-breakpoint-up(xxl) {
                padding-left: calculateRem(55px);
            }

            > * {
                position: relative;
                z-index: 1;
            }

            h2 {
                color: inherit;
                font-size: calculateRem(30px);
            }

            a {
                color: inherit;
                display: inline-block;
                text-decoration: none;

                &::after {
                    content: '';
                    display: block;
                    width: 100%;
                    height: 1px;
                    background-color: currentColor;
                    transition: width 0.3s;
                }
            }

            &::before {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: -1000rem;
                bottom: 0;
                right: -1000rem;
                background-color: $primary-color;
                pointer-events: none;
                z-index: 0;

                @include media-breakpoint-up(md) {
                    left: 0;
                }
            }

            &.center {
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
            }
        }
    }
}
