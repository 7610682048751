.social-links ul {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    list-style: none;

    li {
        margin: 0;
        padding: 0;
        list-style: none;

        &:not(:last-child) {
            margin-right: calculateRem(15px);
        }
    }

    a {
        display: block;
        width: calculateRem(22px);
        font-size: calculateRem(22px);
        text-decoration: none;
        color: #fff;

        &:hover {
            text-decoration: none;
        }

        &::after {
            content: none;
        }

        &::before {
            font-family: "fontello";
            font-style: normal;
            font-weight: normal;
            speak: none;
            text-decoration: none;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
        }

        &.facebook::before {
            content: '\f308';
        }

        &.instagram::before {
            content: '\f16d';
        }

        &.linkedin::before {
            content: '\f30c';
        }
    }
}
