.image-explanation {
    margin-bottom: calculateRem(110px);

    &__image {
        margin: 0 0 calculateRem(40px) 0;

        @include media-breakpoint-up(xxl) {
            margin-left: calculateRem(-100px);
            margin-right: calculateRem(-100px);
        }
    }

    &__items {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    &__item {
        margin: 0;
        padding: 0;
        list-style: none;

        &:not(:last-child) {
            margin-bottom: calculateRem(15px);
        }

        &-count {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            margin-right: calculateRem(10px);
            width: calculateRem(26px);
            height: calculateRem(26px);
            background-color: $primary-color;
            border-radius: 50%;
            color: #fff;
            font-weight: $font-weight-bold;
            line-height: 1;

            @include media-breakpoint-up(md) {
                margin-right: calculateRem(35px);
            }
        }

        &-label {
            margin-right: 5px;
            font-weight: bold;
        }
    }
}
