.ce_accordion {
    margin-bottom: $vr;

    @include media-breakpoint-up(xxl) {
        max-width: calculateRem(865px);
    }

    + .ce_accordion {
        margin-top: -$vr;
    }

    &.active .toggler::after {
        content: '–'
    }

    .toggler {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding: 5px 0;
        border-top: 1px solid $primary-color;
        color: $primary-color;
        font-family: $font-family-headings;
        font-size: calculateRem(30px);
        font-weight: 700;
        line-height: 1.4;
        cursor: pointer;

        &::after {
            content: '+';
            display: flex;
            margin-left: 20px;
            width: 20px;
            justify-content: center;
        }
    }

    &:first-of-type .toggler {
        border-top: 0;
    }

    .accordion {
        transition: height 0.25s ease-in;
        overflow: hidden;

        > div {
            padding: .5rem 0;
        }
    }
}
