@use "sass:math";

.calculator {
    margin-bottom: 0;
    padding: calculateRem(60px) 0;
    background: url('../../images/calculator-bg.jpg') center bottom/cover no-repeat;
    color: #fff;

    &__headline {
        color: inherit;
    }

    &__wrapper {
        @include media-breakpoint-up(xxl) {
            padding-left: calculateRem(290px);
        }
    }

    &__content {
        width: auto;
    }

    &__formula {
        &-headline {
            padding-bottom: calculateRem(50px);
            font-size: calculateRem(28px);
            font-weight: $font-weight-bold;
            text-align: left;
        }

        &-table {
            width: 100%;
            border-spacing: 0;
            border-collapse: collapse;

            tr:not(:last-child) td {
                border-bottom: 3px solid #fff;

                @include media-breakpoint-down(xs) {
                    border-bottom: none;
                }
            }

            @include media-breakpoint-down(xs) {
                display: block;

                thead, tbody, tr, td, th {
                    display: block;
                }

                tbody tr {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    flex-wrap: wrap;

                    &:not(:last-child) {
                        margin-bottom: 1rem;
                        padding-bottom: 1rem;
                        border-bottom: 3px solid #fff;
                    }
                }
            }
        }

        &-label {
            width: 1%;
            white-space: nowrap;
            padding-right: calculateRem(100px);
            font-size: calculateRem(30px);
            font-weight: bold;

            @include media-breakpoint-down(xs) {
                width: 100%;
                padding-right: 0;
            }
        }

        &-value {
            width: 1%;
            white-space: nowrap;
            padding: 5px calculateRem(20px) 5px 0;
            font-size: calculateRem(30px);
            font-weight: bold;

            @include media-breakpoint-down(xs) {
                width: auto;
                padding-right: 0;
            }
        }

        &-input {
            width: calculateRem(180px);
            margin: 0;
            padding: 0 10px;
            background: rgba(255, 255, 255, .5);
            border: none;
            color: inherit;
            font-size: calculateRem(28px);
            font-weight: $font-weight-bold;

            @include media-breakpoint-down(xs) {
                width: 100%;
            }

            &:focus {
                outline: none;
            }

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            &[type=number] {
                -moz-appearance: textfield;
            }
        }

        &-unit {
            font-size: calculateRem(22px);
            font-weight: bold;
        }
    }

    &__results {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: calculateRem(50px) calculateRem(-40px) 0 calculateRem(-40px);

        @include media-breakpoint-up(sm) {
            margin-top: calculateRem(100px);
        }
    }

    &__result {
        width: 100%;
        margin-bottom: calculateRem(40px);
        padding: 0 calculateRem(20px);
        font-weight: $font-weight-bold;
        text-align: center;

        &:last-child {
            margin-bottom: 0;
        }

        @include media-breakpoint-up(sm) {
            padding: 0 calculateRem(40px);
            width: 50%;
            text-align: left;

            &:nth-child(3) {
                margin-bottom: 0;
            }
        }

        @include media-breakpoint-up(lg) {
            margin-bottom: 0;
            width: 25%;
        }

        &-image {
            display: flex;
            justify-content: center;
            align-items: flex-end;
            width: 80px;
            height: 65px;
            margin: 0 auto calculateRem(15px);
            background-position: center bottom;
            background-repeat: no-repeat;
            background-size: contain;

            @include media-breakpoint-up(sm) {
                margin-left: 0;
                margin-right: 0;
            }
        }

        &-value {
            margin-bottom: calculateRem(10px);
            font-size: calculateRem(40px);
            line-height: 1;
        }

        &-label {
            color: $primary-color;
            font-size: calculateRem(20px);
        }
    }

    &__legend {
        display: flex;
        flex-wrap: wrap;
        margin: calculateRem(50px) math.div($grid-gutter, -2) 0;
        padding: calculateRem(40px) 0;
        list-style: none;
        background-color: rgba($primary-color, .24);
        font-size: calculateRem(20px);
        font-weight: $font-weight-bold;

        @include media-breakpoint-up(lg) {
            margin-left: calculateRem(-40px);
            margin-right: calculateRem(-40px);
        }

        &-item {
            width: 100%;
            margin-bottom: calculateRem(40px);
            padding: 0 calculateRem(40px);
            list-style: none;

            &:last-child {
                margin-bottom: 0;
            }

            @include media-breakpoint-up(sm) {
                width: 50%;

                &:nth-child(3) {
                    margin-bottom: 0;
                }
            }

            @include media-breakpoint-up(lg) {
                margin-bottom: 0;
                width: 25%;
            }
        }
    }
}
