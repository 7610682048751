.section-image-left {
    position: relative;
    overflow: hidden;
    padding-top: 2rem;

    @include media-breakpoint-up(md) {
        display: flex;
        padding-top: calculateRem(40px);
    }

    @include media-breakpoint-up(xl) {
        padding-top: calculateRem(90px);
    }

    &::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: -1000rem;
        background-color: #eee;
        z-index: -1;

        @include media-breakpoint-up(md) {
            left: calc((100vw - 720px) / 2); // (100vw - container width) / 2
        }

        @include media-breakpoint-up(lg) {
            left: calc((100vw - 960px) / 2); // (100vw - container width) / 2
        }

        @include media-breakpoint-up(xl) {
            left: calc((100vw - 1140px) / 2); // (100vw - container width) / 2
        }

        @include media-breakpoint-up(xxl) {
            left: calc((100vw - 1680px) / 2); // (100vw - container width) / 2
        }
    }

    .content-image {
        margin: 0 auto 1rem;
        padding: 0 ($grid-gutter * 0.5);

        @include media-breakpoint-up(sm) {
            max-width: 540px;
        }

        @include media-breakpoint-up(md) {
            max-width: 100%;
            min-width: 50%;
            margin: 0;
            padding: 0;
        }

        figure {
            margin: 0;
        }
    }

    .content-image + div {
        align-self: center;
        margin: 0 auto 2rem;
        padding: 0 ($grid-gutter * 0.5);

        @include media-breakpoint-up(sm) {
            max-width: 540px;
        }

        @include media-breakpoint-up(md) {
            max-width: 100%;
            min-width: 50%;
            margin: 0;
            padding-right: calc(50vw - 360px + #{$grid-gutter * 0.5}); // half of .container width + left gutter
            padding-top: calculateRem(40px);
            padding-bottom: calculateRem(40px);
        }

        @include media-breakpoint-up(lg) {
            padding-right: calc(50vw - 480px + #{$grid-gutter * 0.5}); // half of .container width + left gutter
            padding-top: calculateRem(80px);
            padding-bottom: calculateRem(80px);
        }

        @include media-breakpoint-up(xl) {
            padding-right: calc(50vw - 570px + #{$grid-gutter * 0.5}); // half of .container width + left gutter
        }

        @include media-breakpoint-up(xxl) {
            padding-right: calc(50vw - 840px + 145px); // half of .container width + left gutter
            padding-left: calculateRem(110px);
        }

        p:last-child {
            a {
                @extend %link-btn;
            }
        }
    }
}
