.mod_newsreader .back a {
    @extend %link-btn;
}

.news-details {
    padding: calculateRem(58px) 0;

    .section-intro + .mod_article & {
        &:nth-child(even) {
            background-color: transparent;
        }

        &:nth-child(odd) {
            background-color: #eee;
        }
    }

    > .container {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: $vr * 1.5;

        @include media-breakpoint-up(md) {
            flex-wrap: nowrap;
        }
    }

    &__images {
        position: relative;
        width: 100%;
        flex-shrink: 0;

        @include media-breakpoint-up(md) {
            width: 35%;
        }

        figure {
            margin: 0;
            width: 100%;

            img {
                width: 100%;
            }
        }

        .video_container {
            a {
                &:before {
                    content: "";
                    position: absolute;
                    left: 14.4rem;
                    top: 14.4rem;
                    width: 200px;
                    height: 200px;
                    margin-left: -100px;
                    margin-top: -100px;
                    border-radius: 50%;
                    border: 10px solid white;
                    pointer-events: none;
                }

                &:after {
                    content: "";
                    position: absolute;
                    left: 14.4rem;
                    top: 14.4rem;
                    width: 0;
                    height: 0;
                    margin-left: -25px;
                    margin-top: -50px;
                    border-top: 50px solid transparent;
                    border-bottom: 50px solid transparent;
                    border-left: 75px solid #fff;
                    pointer-events: none;
                }
            }
        }

        .swiper {
            &-pagination {
                bottom: calculateRem(14px);

                &-bullet {
                    border-color: #fff;

                    &-active {
                        background-color: #fff
                    }
                }
            }
        }
    }

    &__content {
        order: 1;

        @include media-breakpoint-up(md) {
            order: 0;
        }
    }

    &__info {
        margin-bottom: calculateRem(10px);
        color: #666;
    }

    &__more a {
        @extend %link-btn;
    }

    &__details {
        margin-top: $vr;
        display: flex;
        flex-wrap: wrap;

        @include media-breakpoint-up(md) {
            margin-top: calculateRem(60px);
        }
    }

    &__enclosures {
        display: flex;
        gap: $vr;
        margin: 0 calculateRem(60px) 0 0;
        padding: 0;
        list-style: none;
    }

    &__enclosure {
        margin: 0;
        padding: 0;
        list-style: none;

        &-link {
            display: block;
            width: 60px;
            height: 75px;
            background: url('../../images/icon-pdf.svg') center/contain no-repeat;
            transition: all $transition-time;

            &:hover {
                transform: translateY(10%);
            }
        }
    }

    &__co2 {
        color: $primary-color;
        font-weight: $font-weight-bold;
        text-align: center;

        &-value {
            font-size: calculateRem(40px);
        }
    }
}
